// Imports
import React from "react";
import PropTypes from "prop-types";

//Global CSS
import "../assets/css/globalReset.css";
import "../assets/css/globalFonts.css";
import "../assets/css/globalStyles.css";
import "../assets/css/swiper.css";

// CSS
import * as Styles from "./css/Layout.module.css";

// Components
import Header from "./Header";
import Footer from "./Footer";
import Seo from "./SEO";

// ======== Component ========
const Layout = (props) => {
    return (
        <main className={`flex flex-jc-fs ${Styles.layout}`}>
            <Seo
                title={"Healthy mind, Healthy body"}
                desc={
                    "No one is immune to mental health issues. That’s why we’re providing the tips and tools you need for a healthy mind and body. Check back often for new resources!"
                }
            />
            <Header />
            <div className={Styles.layoutMain}>{props.children}</div>
            <Footer />
        </main>
    );
};

// Prop validation
Layout.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
    desc: PropTypes.string,
};

Layout.defaultProps = {
    title: "Balanced Body",
};

export default Layout;
