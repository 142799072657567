// extracted by mini-css-extract-plugin
export var footer = "Footer-module--footer--6626c";
export var footerIcon = "Footer-module--footerIcon--4b942";
export var footerIconContainer = "Footer-module--footerIconContainer--ec5aa";
export var footerInputContainer = "Footer-module--footerInputContainer--f14da";
export var footerInputMessage = "Footer-module--footerInputMessage--fe81e";
export var footerLeftContainer = "Footer-module--footerLeftContainer--f5c64";
export var footerLogo = "Footer-module--footerLogo--0dcd3";
export var footerPilatesLogo = "Footer-module--footerPilatesLogo--aba9f";
export var footerRightContainer = "Footer-module--footerRightContainer--838c4";
export var footerSubmitButton = "Footer-module--footerSubmitButton--b1b15";