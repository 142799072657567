// Imports
import React from "react";

// CSS
import * as Styles from "./css/Footer.module.css";

// SVG
import Logo from "../assets/images/logo_footer.svg";
import Fb from "../assets/images/logo_fb.svg";
import Ig from "../assets/images/logo_ig.svg";

// ======== Component ========
const Footer = () => {
    // State
    // const [inputVal, setInputVal] = useState("");
    // const [message, setMessage] = useState("");
    // const [disabled, setDisabled] = useState(false);

    // const validateEmail = () => {
    //     const regex =
    //         /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     return regex.test(String(inputVal).toLowerCase());
    // };

    // const handleSubmit = () => {
    //     setMessage("");
    //     setDisabled(true);

    //     const isValid = validateEmail();
    //     if (!isValid) {
    //         setMessage("A valid email address is required.");
    //         setDisabled(false);
    //         return;
    //     } else {
    //         setMessage("Sending...");
    //         const data = {
    //             emailaddress: inputVal,
    //             attributes: [{ "IMS Program and Events": "true" }],
    //         };
    //         let headers = new Headers();
    //         headers.append("Content-Type", "application/json");
    //         const requestOptions = {
    //             method: "POST",
    //             headers: headers,
    //             body: JSON.stringify(data),
    //         };

    //         fetch("https://websiteapis.azurewebsites.net/Marketing/CustomerIORequest", requestOptions)
    //             .then((res) => {
    //                 setDisabled(true);
    //                 setMessage("Thank you for subscribing!");
    //                 if (res.status !== 200) throw Error(setMessage(`POST error code ${res.status}`));
    //             })
    //             .catch((error) => {
    //                 setDisabled(false);
    //                 console.log(error);
    //             });
    //     }
    // };

    return (
        <footer className={`flex ${Styles.footer}`}>
            {/* Left */}
            {/* <div className={`flex flex-ai-fs ${Styles.footerLeftContainer}`}>
                <p>Get Updates on the Integrated Movement&nbsp;Series!</p>
                <div className={`flex flex-row ${Styles.footerInputContainer}`}>
                    <input
                        value={inputVal}
                        placeholder="Email"
                        onChange={(e) => {
                            setInputVal(e.target.value);
                        }}
                    />
                    <button
                        type="submit"
                        onClick={handleSubmit}
                        disabled={disabled}
                        className={Styles.footerSubmitButton}
                    >
                        Subscribe
                    </button>
                    <p className={`body-text ${Styles.footerInputMessage}`}>{message}</p>
                </div>
            </div> */}
            {/* Right */}
            <div className={`flex ${Styles.footerRightContainer}`}>
                <a className={Styles.footerPilatesLogo} href="https://www.pilates.com">
                    <Logo className={Styles.footerLogo} />
                </a>
                <div className={`flex flex-row ${Styles.footerIconContainer}`}>
                    <a href="https://www.facebook.com/BalancedBodyInc/" target="_blank" rel="noreferrer">
                        <Fb className={Styles.footerIcon} />
                    </a>
                    <a href="https://www.instagram.com/balanced_body/" target="_blank" rel="noreferrer">
                        <Ig className={Styles.footerIcon} />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
