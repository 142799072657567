// Imports
import React from "react";

// CSS
import * as HeaderStyles from "./css/Header.module.css";

// Components
import Logo from "../assets/images/bb-logo-header.svg";

// ======== Component ========
const Header = () => {
    return (
        <header className={`flex w100 ${HeaderStyles.header}`}>
            <a href="https://www.pilates.com" target="_blank" rel="noreferrer noopener">
                <Logo className={HeaderStyles.logo} />
            </a>
        </header>
    );
};

export default Header;
